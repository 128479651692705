<template>
  <div class="app-customizer">
    <v-btn
      icon
      class="app-customizer-toggler rounded-0"
      :class="$vuetify.rtl ? 'rounded-r-lg' : 'rounded-l-lg'"
      color="white"
      large
      @click="isCustomizerOpen = !isCustomizerOpen"
    >
      <v-icon size="25">
        {{ icons.mdiFilterMenu }}
      </v-icon>
    </v-btn>
    <v-navigation-drawer
      v-model="isCustomizerOpen"
      :right="!$vuetify.rtl"
      temporary
      fixed
      width="400"
      class="app-customizer-drawer"
    >
      <div class="app-customizer-header customizer-section py-3">
        <span :style="`color: ${$vuetify.theme.themes.light.primary};font-size: 18px`">
          <b>{{ $t('menu.cars') }} </b>
          <span
            class="ml-5"
            :style="`color: ${$vuetify.theme.themes.light.primary};text-decoration: underline;cursor: pointer;font-size: 14px;`"
            @click="clearFiltros"
          >{{ $t('lbl.clearFilters') }}</span>
        </span><br />
        <v-btn
          icon
          class="icon-customizer-close"
          @click="isCustomizerOpen = false"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-divider></v-divider>

      <perfect-scrollbar
        :options="perfectScrollbarOptions"
        class="ps-customizer"
      >
        <!--RENTADORA-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.rental') }}</span>
          <v-autocomplete
            v-model="filtersCars.rentadora_id"
            class="pt-2"
            :items="itemsRentadora"
            :search-input.sync="searchRentadora"
            hide-details
            hide-selected
            :label="$t('lbl.rental')"
            outlined
            dense
            item-text="name"
            item-value="id"
            clearable
            @change="refresh()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.rental') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <v-divider></v-divider>

        <!--PROVEEDOR-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.proveedor') }}</span>
          <v-autocomplete
            v-model="filtersCars.proveedor_id"
            class="pt-2"
            :items="itemsProveedor"
            :search-input.sync="searchProveedor"
            hide-details
            hide-selected
            :label="$t('lbl.proveedor')"
            outlined
            dense
            item-text="name_comercial"
            item-value="id"
            clearable
            @change="refresh()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.proveedor') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name_comercial"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name_comercial }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </div>
        <v-divider></v-divider>

        <!--IDENTIFICADOR-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.identificador') }}</span>
          <v-text-field
            v-model="filtersCars.identificador"
            :label="$t('lbl.identificador')"
            class="pt-2"
            hide-details
            outlined
            dense
            single-line
            :append-icon="icons.mdiMagnify"
            @input="refresh()"
          >
          </v-text-field>
        </div>
        <v-divider></v-divider>

        <!--DATES-->
        <div class="customizer-section">
          <v-row>
            <v-col cols="12">
              <span class="text--primary">{{ $t('lbl.from') }}</span>
              <v-menu
                v-model="menuFrom"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedFromDateFormatted"
                    :label="$t('lbl.from')"
                    class="pt-2"
                    hide-details
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filtersCars.from"
                  no-title
                  locale="es"
                  :show-current="false"
                  @input="
                    menuFrom = false
                    activeToDate()
                    refresh()
                  "
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12">
              <span class="text--primary">{{ $t('lbl.to') }}</span>
              <v-menu
                v-model="menuTo"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedToDateFormatted"
                    :label="$t('lbl.to')"
                    class="pt-2"
                    hide-details
                    persistent-hint
                    readonly
                    v-bind="attrs"
                    outlined
                    dense
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="filtersCars.to"
                  no-title
                  locale="es"
                  :show-current="false"
                  :min="
                    filtersCars.from
                      ? $moment(filtersCars.from)
                        .add(1, 'day')
                        .toDate()
                        .toISOString()
                        .substr(0, 10)
                      : ''
                  "
                  @input="menuTo = false;refresh()"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <v-divider></v-divider>

        <!--ESTADO-->
        <div class="customizer-section">
          <span class="text--primary">{{ $t('lbl.state') }}</span>
          <v-select
            v-model="filtersCars.state"
            :items="states"
            :label="$t('lbl.state')"
            class="pt-2"
            outlined
            dense
            hide-details
            item-text="name"
            item-value="value"
            @change="refresh()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.state') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-select>
        </div>
        <v-divider></v-divider>
      </perfect-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'

import {
  mdiFilterMenu, mdiClose, mdiStarOutline, mdiStar, mdiStarHalfFull, mdiMagnify,
} from '@mdi/js'

import { PerfectScrollbar } from 'vue2-perfect-scrollbar'

// 3rd Party

export default {
  components: {
    PerfectScrollbar,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    user: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    proveedores: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    rentadoras: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    states: {
      type: Array,
    },
  },
  setup() {
    const isCustomizerOpen = ref(false)
    // eslint-disable-next-line object-curly-newline
    const {
      isDark,
      isRtl,
      themes,
    } = useAppConfig()

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      isCustomizerOpen,

      isDark,
      isRtl,
      themes,

      // Perfect scrollbar options
      perfectScrollbarOptions,
    }
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      icons: {
        mdiFilterMenu,
        mdiClose,
        mdiStarOutline,
        mdiStar,
        mdiStarHalfFull,
        mdiMagnify,
      },
      permisos: sessionStorage.getItem('permisos_auth'),
      itemsRentadora: [],
      searchRentadora: null,
      itemsProveedor: [],
      searchProveedor: null,
      menuFrom: false,
      menuTo: false,
      isLoading: true,

      filtersCars: {},
    }
  },
  computed: {
    ...mapState({
      // filtersCars: state => state.app.filtersCars,
    }),
    computedFromDateFormatted() {
      return this.filtersCars.from ? this.$moment(this.filtersCars.from).format('D-MMM-YY') : ''
    },
    computedToDateFormatted() {
      return this.filtersCars.to ? this.$moment(this.filtersCars.to).format('D-MMM-YY') : ''
    },
  },
  watch: {
    searchRentadora(val) {
      if (val !== null && val.length > 1) {
        this.filterRentadora(val.toLowerCase())
      }
    },
    searchProveedor(val) {
      if (val !== null && val.length > 0) {
        this.filterProveedor(val.toLowerCase())
      }
    },
  },
  created() {
    this.allHotel = this.hotelesAll
    this.allMarcas = this.allMarcasList
  },
  methods: {
    ...mapMutations([
      'filtrarCars',
      'filtersCotizadorHotelsResult',
      'clearCotizadorHotelsResultFilters',
      'clearFiltrosCars',
    ]),
    filterRentadora(v) {
      this.itemsRentadora = []
      if (v === '') {
        this.itemsRentadora = []
      } else {
        this.itemsRentadora = this.rentadoras.filter(e => e.name.toLowerCase())
      }
    },
    filterProveedor(v) {
      this.itemsProveedor = []
      if (v === '') {
        this.itemsProveedor = []
      } else {
        this.itemsProveedor = this.proveedores.filter(e => e.name_comercial.toLowerCase())
      }
    },
    activeToDate() {
      /* if (this.filtersCars.from) {
        setTimeout(() => {
          this.menuTo = true
        }, 100)
      } */
    },

    clearFiltros() {
      this.filtersCars = {}
      this.$emit('refresh', { })
      this.isCustomizerOpen = false
    },
    refresh() {
      this.$emit('refresh', this.filtersCars)
    },
  },
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

.ps-customizer {
  height: calc(100% - 81px) !important;
}

.app-customizer-toggler {
  position: fixed;
  top: 50%;
  transform: translateX(-50%);
  background: var(--v-primary-base);
  @include ltr() {
    right: -22px;
  }
  @include rtl() {
    left: 20px;
  }
}

@include theme(app-customizer-drawer) using ($material) {
  background-color: map-deep-get($material, 'cards');
}

.app-customizer {
  z-index: 7;

  .v-label {
    font-size: 0.875rem;
  }

  .app-customizer-header {
    position: relative;
    .icon-customizer-close {
      position: absolute;
      @include ltr() {
        right: 20px;
      }
      @include rtl() {
        left: 20px;
      }
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .customizer-section {
    padding: 24px;
  }

  // Fixes Overlay is shown below SystemBar
  @at-root {
    .v-overlay {
      & + .v-application--wrap > .v-system-bar {
        z-index: 6 !important;
      }
    }
  }
}
</style>
